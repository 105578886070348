<template>
    <SurveyLayout class="override" :progressStep="4" :questionId="4" nextPageId="SurveyPage05">
        <template #card-title>Number of Giveaway Rounds</template>
        <template #question>How frequent do you want the Giveaway rounds to be?</template>
        
        <template #choice-a>Every 150 rounds</template>
        <template #choice-b>Every 175 rounds</template>
        <template #choice-c>Every 200 rounds</template>

        <template #tip-a>Vote for lower values if you prefer <span class="prominent">most Alpha Goat</span> should be distributed in a <span class="prominent">fair way</span></template>
        <template #tip-b>Vote for higher multipliers if you prefer <span class="prominent">Less Competition</span></template>

        <template #help-title>Giveaway Rounds</template>
        <template #help-content>
            <p>Giveaway Rounds are rounds in which <span class="title small">the voting fee is free</span>, and there’ll be <span class="title small">no auctions</span>, which means one of the voters to winner goat will receive the winner goat in a <span class="title small">lucky draw</span>.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/gifts.png');

    --img-override-origin: border-box;
    --img-override-position: top center;
}

</style>