<template>
    <button @click="handleClick" :disabled="!active">
        <template v-if="title">{{ title }}</template>
        <slot></slot>
    </button>
</template>

<script>
import * as telegramManager from '@/telegramManager';

export default {
    inject: ['goToPage'],
    emits: ['button-clicked'],
    props: {
        title: String,
        url: {type: String, default: null},
        pageId: {type: String, default: null},
        active: {type: Boolean, default: true}
    },
    methods: {
        handleClick() {
            this.$emit('button-clicked');

            if (this.url) {
                telegramManager.openLink(this.url);
            } else if (this.pageId) {
                this.goToPage(this.pageId);
            }
        }
    }
}
</script>
