<template>
    <SlideIntroLayout class="override" :slideIndex="2" :durationMs="5000" nextPageId="AlphaGoat01S4">
        <template #description>
            Your Visa to the Alpha Lands
        </template>
    </SlideIntroLayout>
</template>

<script>
export default {
    
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/intro/intro-3.png');
}

</style>