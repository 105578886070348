<template>
  <AssetPreloader />
  <PageManager />
</template>

<script>
import AssetPreloader from './components/AssetPreloader.vue';
import PageManager from './components/PageManager.vue';

export default {
  components: {
    AssetPreloader,
    PageManager,
  }
};
</script>
