<template>
    <SurveyLayout class="override" :progressStep="2" :questionId="2" nextPageId="SurveyPage03">
        <template #card-title>Auction Round Duration</template>
        <template #question>How long do you prefer the auctions to remain live?</template>
        
        <template #choice-a>2 minutes</template>
        <template #choice-b>5 minutes</template>
        <template #choice-c>10 minutes</template>

        <template #tip-a>Vote for lower values if you prefer <span class="prominent">more random distribution</span> to voters</template>
        <template #tip-b>Vote for higher values if you prefer to have <span class="prominent">enough time to participate</span> in the auction</template>

        <template #help-title>Auction Round Duraiton</template>
        <template #help-content>
            <p>Each auction round runs in a limited time frame.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/auction/auction-time.png');
}

</style>