<template>
    <SurveyLayout class="override" :progressStep="9" :questionId="9" nextPageId="SurveyPage10" nextButtonTitle="Almost there...!">
        <template #card-title>Number of Limited Participants Rounds</template>
        <template #question>How much do you want to reward early voters?</template>
        
        <template #choice-a>Every 100 rounds <br><span class="small">with a limitation of 100 Participants</span></template>
        <template #choice-b>Every 150 rounds <br><span class="small">with a limitation of 50 Participants</span></template>
        <template #choice-c>Every 200 rounds <br><span class="small">with a limitation of 20 Participants</span></template>

        <template #tip-a>Vote for less limitations if you want <span class="prominent">more chance for everyone</span></template>
        <template #tip-b>Vote for higher limitations if you want to <span class="prominent">be early and be rewarded for that</span></template>

        <template #help-title>Limited Participants Rounds</template>
        <template #help-content>
            <p>Limited Participants' Rounds accept only <span class="title small">a set number of first votes</span>, focusing competition on <span class="title small">early voting</span>. The rest follows the same structure as a regular round.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/fence.png');
}

</style>