<template>
    <SurveyLayout class="override" :progressStep="5" :questionId="5" nextPageId="SurveyPage06">
        <template #card-title>Founders Allocation</template>
        <template #question>How do you prefer the founders to take their allocation?</template>
        
        <template #choice-a>2% Distributed Consistently</template>
        <template #choice-b>4% from Less Rare Tiers</template>
        <template #choice-c>1% from More Rare Tiers</template>

        <template #tip-a>Vote for More Rare Tiers if you want the <span class="prominent">team share to be lower</span></template>
        <template #tip-b>Vote for more share if you want <span class="prominent">more rare items for the community</span></template>

        <template #help-title>Founders Rounds</template>
        <template #help-content>
            <p>Founders Rounds are rounds where <span class="title small">voting is free</span>, but there will be no auctions and no drop to users. Instead, the winner goat goes to the <span class="title small">AlphaLabs wallet</span>. (Through this mechanism team transparently gets its allocation)</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/shares.png');
}

</style>