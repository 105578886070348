<template>
    <SurveyLayout class="override" :progressStep="10" :questionId="10" nextPageId="SurveyPage11" nextButtonTitle="To infinity and beyond">
        <template #card-title>Number of Losers Rounds</template>
        <template #question>How frequently do you prefer to give our unlucky friends a second chance?</template>
        
        <template #choice-a>Every 100 rounds</template>
        <template #choice-b>Every 150 rounds</template>
        <template #choice-c>Every 200 rounds</template>

        <template #tip-a>Vote for less values if you feel <span class="prominent">competitive</span></template>
        <template #tip-b>Vote for higher values if you feel compassionate</template>

        <template #help-title>Losers Rounds</template>
        <template #help-content>
            <p>Losers rounds are rounds where there’s <span class="title small">no voting fee or auction</span>, and the winner goat goes to a <span class="title small">lucky draw</span> winner amongst all the people who have lost a lucky draw from the previous losers’ round to this one.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/target.png');
}

</style>