<template>
    <PageBaseLayout>
        <template #main>
            <div style="position: relative;">
                <div class="container">
                    <div class="steps" ref="steps">
                        <div class="step-fill"></div>
                        <div class="step-fill"></div>
                        <div class="step-fill"></div>
                        <div class="step-fill"></div>
                    </div>

                    <h6 class="light text-left">
                        <slot name="description">                            
                        </slot>
                    </h6>

                </div>
                <div class="bg-image">
                </div>

                <div class="tap-panel" ref="tapPanel">
                </div>
            </div>
        </template>

        <template #bottom v-if="showNextButton">
            <div class="bottom">
                <BaseButton :pageId="nextPageId">{{ nextButtonTitle }} &nbsp;<i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </PageBaseLayout>
</template>

<script>

const pointerHandler = {
    onPointerDown() {
        pointerHandler._onHold();
    },
    onPointerUp() {
        pointerHandler._onRelease();
    },
    addListener(element, onHold, onRelease) {
        this._onHold = onHold;
        this._onRelease = onRelease;
        this._element = element;
        element.addEventListener('pointerdown', this.onPointerDown);
        element.addEventListener('pointerup', this.onPointerUp);
        element.addEventListener('pointercancel', this.onPointerUp);
    },
    cleanUp() {
        this._element?.removeEventListener('pointerdown', this.onPointerDown);
        this._element?.removeEventListener('pointerup', this.onPointerUp);
        this._element?.removeEventListener('pointercancel', this.onPointerUp);
        this._element = null;
        this._onHold = null;
        this._onRelease = null;
    }
};

export default {
    inject: ['goToPage'],
    props: {
        slideIndex: {type: Number, default: 0},
        durationMs: {type: Number, default: 3000},
        nextPageId: {type: String, default: null},
        nextButtonTitle: {type: String, default: 'Next'},
        autoNext: {type: Boolean, default: true}
    },
    mounted() {
        for(let i=0;i<this.slideIndex;i++) {
            this.updateFill(i, 1.0); // fill previous steps
        }

        this._shouldAnimate = true;
        this._pauseUpdate = false;

        const tapPanel = this.$refs.tapPanel;
        const thisRef = this;

        pointerHandler.addListener(tapPanel, 
            () => thisRef._pauseUpdate = true,      // on hold
            () => thisRef._pauseUpdate = false);    // on release

        this.animateFill(this.slideIndex, this.durationMs);
    },
    unmounted() {
        this._shouldAnimate = false;

        pointerHandler.cleanUp();
    },
    methods: {
        updateFill(stepIndex, fillAmount) {
            const stepFills = this.$refs.steps.querySelectorAll('.step-fill');
            stepFills[stepIndex].style.setProperty('--fill-amount', fillAmount);
        },
        animateFill(stepIndex, duration) {
            const thisRef = this;
            let elapsed = 0;
            let lastTimestamp = null;

            function finishTimer() {
                if (thisRef.autoNext) {
                    thisRef.goToPage(thisRef.nextPageId);
                }
                else {
                    thisRef.showNextButton = true;
                }
            }
            
            function animate(timestamp){
                if (!thisRef._shouldAnimate) {
                    return;
                }

                if (!lastTimestamp) {
                    lastTimestamp = timestamp;
                    elapsed = 0;
                }

                if (!thisRef._pauseUpdate)
                {
                    const deltaTime = timestamp - lastTimestamp;
                    lastTimestamp = timestamp;
                    elapsed += deltaTime;
                    const t = Math.min(1, elapsed / duration);
                    thisRef.updateFill(stepIndex, t);
                }

                if (elapsed < duration) {
                    // request next frame
                    requestAnimationFrame(animate);
                } else {
                    finishTimer();
                }
            }

            requestAnimationFrame(animate);
        }
    },
    data() {
        return {
            showNextButton: false,
        }
    }
}
</script>

<style lang="scss" scoped>

.bg-image {
    min-height: 390px;

    background-repeat: no-repeat;
    background-size: cover;

    background-image: var(--img-override);
    background-origin: var(--img-override-origin, content-box);
    background-position: var(--img-override-position, center);
}

.steps {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-bottom: 20px;

    .step-fill {
        position: relative;
        flex-grow: 1;
        height: 5px;
        border-radius: 10px;
        background-color: var(--color-slide-back);

        &::after {
            content: '';
            position: absolute;
            inset: 0 calc((1.0 - var(--fill-amount, 0)) * 100%) 0 0;
            border-radius: 10px;
            background-color: var(--color-slide-fill);
        }
    }
}

.tap-panel {
    position: absolute;
    display: flex;
    inset: 0;
}

</style>