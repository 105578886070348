<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header-super>The first</template>
        <template #header>10K NFT Collection</template>
        <template #header-sub>on ERC-7160 standard</template>
        
        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="AlphaGoat03">What else? <i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/logo.svg');
}
</style>