<template>
    <PageBaseLayout>
        <template #main>
            <div class="container">
                <div class="card celebrate text-left">
                    <h6>Congrats!</h6>
                    <p class="large" style="margin-top: 10px;">You’ve Mastered the Launch Process!</p>
                </div>

                <div class="text-left" style="margin-top: 20px; padding: 20px;">
                    <p><span class="title small">Awesome work</span>! <br>You now have a complete understanding of how <span class="title small">GEM</span> works, from <span class="title small">Voting</span> to <span class="title small">Auctions</span>.</p>
                    <br>
                    <p>Head back to the home screen - there’s a surprise waiting for you</p>
                </div>
            </div>
        </template>

        <template #bottom>
            <div class="bottom">            
                <BaseButton class="hollow" pageId="HomePage" @button-clicked="setCompleted">Back to Home &nbsp;<i class="icon middle home"></i></BaseButton>
            </div>
        </template>
    </PageBaseLayout>
</template>

<script>
import storage from '@/storage.js';

export default {
    methods: {
        setCompleted() {
            storage.setItem('doneTutorial', '1');
        }
    }
};
</script>

<style lang="scss" scoped>
.container .card {
    height: 204px;
}
</style>