<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header>Gamification</template>
        
        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="HomePage" class="hollow">Back to Home <i class="icon middle home"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/logo.svg');
}

.bottom button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

</style>