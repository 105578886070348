const telegram = window.Telegram.WebApp;

export function initialize() {
    telegram.ready();
    telegram.expand();
    
    if(telegram.isVersionAtLeast('6.2')) {
      telegram.enableClosingConfirmation();
    }
  
    if(telegram.isVersionAtLeast('7.7')) {
      telegram.disableVerticalSwipes();
    }
}

function showPopup(popupParams, callback) {
    if (telegram.isVersionAtLeast('6.2')) {
        telegram.showPopup(popupParams, callback);
        return;
    }

    console.warn('telegram popup is not supported, falling back to simple alert.');
    alert(`${popupParams.title}: ${popupParams.message}`);
}

export function showPopupSimple(title, message, buttonText = null) {
    let buttons = [];
    buttons.push(buttonText ? {text: buttonText} : {type: 'close'});
    showPopup({title, message, buttons});
}

export function openLink(url) {
    if (telegram.isVersionAtLeast('6.4')) {
        telegram.openLink(url);
        return;
    }

    window.open(url, '_blank');
}

export function closeApp() {
    telegram.close();
}