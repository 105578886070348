<template>
    <InfoLayout2 :nextPage="{buttonTitle: 'Give It Another Go', id: 'GemMechanism09Win'}">
        <template #header-title>&nbsp;</template>

        <template #main>
            <div style="margin-top: -16px;">
                <div>
                    <img class="center" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMzMiIGN5PSI0MCIgcj0iNi41IiBzdHJva2U9IiNDRkNBREEiIHN0cm9rZS13aWR0aD0iMyIvPgo8Y2lyY2xlIGN4PSIzMyIgY3k9IjQwIiByPSIxNy41IiBzdHJva2U9IiNDRkNBREEiIHN0cm9rZS13aWR0aD0iMyIvPgo8Y2lyY2xlIGN4PSIzMyIgY3k9IjQwIiByPSIyOS41IiBzdHJva2U9IiNDRkNBREEiIHN0cm9rZS13aWR0aD0iMyIvPgo8cGF0aCBkPSJNNjIuODY5NiA3OC4wMTI0TDY5LjQyMTYgNjMuMTI3MU03NS45NzM1IDQ4LjI0MThMNjkuNDIxNiA2My4xMjcxTTc1Ljk3MzUgNDguMjQxOEw3Mi42MDA0IDQzLjc5NkM3Mi4xMzk1IDQzLjE4ODUgNzEuMTk1NiA0My4yOTk3IDcwLjg4ODUgNDMuOTk3NUw2NS41MjE3IDU2LjE5MDNDNjUuMzg3IDU2LjQ5NjMgNjUuNDE0MSA1Ni44NDk0IDY1LjU5NCA1Ny4xMzEzTDY5LjQyMTYgNjMuMTI3MU03NS45NzM1IDQ4LjI0MThMODIuNTM0NCA0OC4zMDU3QzgzLjI1MjMgNDguMzEyNyA4My43MjkxIDQ5LjA1MTUgODMuNDM5OSA0OS43MDg2TDc4LjAyNzUgNjIuMDA0OEM3Ny44Nzc3IDYyLjM0NTMgNzcuNTUxMiA2Mi41NzQ1IDc3LjE4MDEgNjIuNTk5N0w2OS40MjE2IDYzLjEyNzEiIHN0cm9rZT0iI0NGQ0FEQSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTQ1LjUgNzcuNUw0MiA2OC41TTE5IDc3LjVMMjIuNSA2OC41IiBzdHJva2U9IiNDRkNBREEiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=">
                </div>
                <p class="title">Missed the Mark? <br>Let’s Fix That!</p>
            </div>
            
            <div class="text-left" style="padding: 20px;">
                <p>Sometimes votes don’t go as planned, but that's why we're here to practice. We invite you to try the voting simulation again to experience <span class="title small">what happens when your vote is successful</span>.</p>
            </div>
        </template>
    </InfoLayout2>
</template>


<style scoped>
p {
    margin-bottom: 1em;
}
</style>