<template>
    <div class="info-box rounded">
        <div class="icon"></div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>

.info-box {
    position: relative;
    padding: 20px;
    border: 2px solid var(--color-secondary-light);
    border-top: none;
    margin-top: 20px;
    
    &::before {
        content: '';
        position: absolute;
        inset: 0 60% 0 0;
        border-top: solid 2px var(--color-secondary-light);
        border-top-left-radius: 16px;
    }

    &::after {
        content: '';
        position: absolute;
        inset: 0 0 0 60%;
        border-top: solid 2px var(--color-secondary-light);
        border-top-right-radius: 16px;
    }

    .icon {
        position: absolute;
        top: 0;
        left: 50%;        
        width: 40px;
        height: 40px;
        transform: translateX(-50%) translateY(-50%);
        
        background: no-repeat var(--icon-img, url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS43NSIgeT0iMS43NSIgd2lkdGg9IjIwLjUiIGhlaWdodD0iMjAuNSIgcng9IjEwLjI1IiBzdHJva2U9IiNCRkJGQkYiIHN0cm9rZS13aWR0aD0iMS41Ii8+CjxyZWN0IHg9IjEwLjI4MTIiIHk9IjUuMTI1IiB3aWR0aD0iMy40Mzc1IiBoZWlnaHQ9IjMuNDM3NSIgcng9IjEuNzE4NzUiIGZpbGw9IiNCRkJGQkYiLz4KPHJlY3QgeD0iMTAuNjI1IiB5PSIxMC42MjUiIHdpZHRoPSIyLjc1IiBoZWlnaHQ9IjguMjUiIHJ4PSIxLjM3NSIgZmlsbD0iI0JGQkZCRiIvPgo8L3N2Zz4K"));
        background-position: center;
    }
}


</style>