import * as survey from "@/survey.js";
import * as errors from "@/errors.js";

//TODO remove (for test)
// import "@/localtest";

function validateTelegramSupport() {
    if(!(window?.Telegram?.WebApp?.initData)) {
        alert('Unsupported platform!');
        console.error('unsupported platform!');
        return false;
    }

    return true;
}

async function sendPost(endpoint, data) {
    if (!validateTelegramSupport()) {
        return errors.create(errors.ERROR_IDS.INVALID_PLATFORM);
    }

    const telegramAuth = window.Telegram.WebApp.initData;
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'X-Telegram-Auth': telegramAuth
        },
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            console.error({code: response.status, status: response.statusText});
            return errors.create(errors.ERROR_IDS.UNKNOWN_ERROR);
        }
        let data = await response.json();
        return data;
    } catch(e) {
        return errors.create(errors.ERROR_IDS.UNKNOWN_ERROR);
    }
}

export async function submitSurvey() {
    console.log(survey.serialize(false));
    return await sendPost('/api/survey', survey.serialize(false));
}