<template>
    <div>
        <div class="scroll-container" @scroll="checkScroll" ref="container">
            <slot></slot>
        </div>
        <div v-if="showArrow" class="down-arrow back-blur-medium">
            <img src="@/assets/imgs/down.svg" alt="down" class="center">
        </div>
    </div>
</template>
  
<script>
  export default {
    emits: ['state-changed'],
    mounted() {
        this.checkScroll();
        this.emitStateChanged();
        window.addEventListener("resize", this.checkScroll);
    },
    unmounted() {
        window.removeEventListener("resize", this.checkScroll);
    },
    methods: {
        checkScroll() {
            const container = this.$refs.container;
            if (!container) {
                return;
            }
            const scrollHeight = container.scrollHeight - 1; // tolerance offset: 1
            const canScroll = scrollHeight > container.clientHeight;
            const reachedBottom = container.scrollTop + container.clientHeight >= scrollHeight;
            const wasShowingArrow = this.showArrow;
            
            this.showArrow = canScroll && !reachedBottom;
            
            if (this.showArrow != wasShowingArrow) {
                this.emitStateChanged();
            }
        },
        
        emitStateChanged() {
            this.$emit('state-changed', this.showArrow);
        }
    },
    data() {
      return {
        showArrow: true,
      };
    },
  };
</script>
  
<style>
.scroll-container {
    padding-top: 60px;
    height: var(--vh, 100vh);
    overflow-y: auto;
    overflow-x: hidden;
}
  
.down-arrow {
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 16px;
    width: 100%;
    pointer-events: none;
}
</style>
