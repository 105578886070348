<template>
    <SurveyLayout class="override" :progressStep="7" :questionId="7" nextPageId="SurveyPage08" nextButtonTitle="Hell Yeah!">
        <template #card-title>Number of Mysterious Rounds</template>
        <template #question>How frequently do you find Mysterious Rounds fun?</template>
        
        <template #choice-a>Every 100 rounds</template>
        <template #choice-b>Every 150 rounds</template>
        <template #choice-c>Every 200 rounds</template>

        <template #tip-a>Vote for less values if you want to have <span class="prominent">more control</span></template>
        <template #tip-b>Vote for higher values if you want to have <span class="prominent">more fun and excitement</span></template>

        <template #help-title>Mysterious Rounds</template>
        <template #help-content>
            <p>Mysterious Rounds are rounds in which users <span class="title small">cannot see the artwork of the goats</span> they are voting for. Instead, they see a placeholder, and they choose <span class="title small">blindly</span>.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/chest.png');
}

</style>