<template>
    <SurveyLayout class="override" :progressStep="1" :questionId="1" nextPageId="SurveyPage02">
        <template #card-title>Voting Round Duration</template>
        <template #question>How long do you prefer to be able to vote?</template>
        
        <template #choice-a>2 minutes</template>
        <template #choice-b>5 minutes</template>
        <template #choice-c>10 minutes</template>

        <template #tip-a>Vote for lower values if you think <span class="prominent">Early Voters</span> should be rewarded</template>
        <template #tip-b>Vote for higher values if you prefer <span class="prominent">everyone</span> should have <span class="prominent">more time</span> and <span class="prominent">opportunity</span> to participate</template>

        <template #help-title>Voting Round Duration</template>
        <template #help-content>
            <p>Each voting round runs in a limited time frame.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/time-limit.png');
}

</style>