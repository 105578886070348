import pagesData from "@/data/pagesData";

const pages = pagesData;

export default {
    getEntryPageId() {
        return 'HomePage';
    },
    registerComponents(components) {
        for(let key in pages) {
            let page = pages[key];
            components[page.name] = page.component;
        }
    },

    getCategory(pageId) {
        return pages[pageId].category;
    },
    getComponent(pageId) {
        return pages[pageId].name;
    }
};