<template>
    <InfoLayout2 :nextPage="nextPage">
        <template #page-title>Other Rounds</template>
        <template #header-title>&nbsp;</template>

        <template #main>
            <div class="list" ref="list">
                <!-- Giveaway Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg1"></div>
                        <div>
                            <h6>Giveaway Rounds</h6>
                            <p>Giveaway Rounds are rounds in which <span class="title small">the voting fee is free</span>, and there’ll be <span class="title small">no auctions</span>, which means one of the voters to winner goat will receive the winner goat in a <span class="title small">lucky draw</span>.</p>
                        </div>
                    </div>
                </div>

                <!-- Mysterious Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg2"></div>
                        <div>
                            <h6>Mysterious Rounds</h6>
                            <p>Mysterious Rounds are rounds in which users <span class="title small">cannot see the artwork of the goats</span> they are voting for. Instead, they see a placeholder, and they choose <span class="title small">blindly</span>.</p>
                        </div>
                    </div>
                </div>

                <!-- Double Reward Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg3"></div>
                        <div>
                            <h6>Double Reward Rounds</h6>
                            <p>In Double Reward rounds, the winner claims both <span class="title small">the current and next round’s prizes</span>. The following round has free voting, no auctions, and the winning goat automatically transfers to the previous round's winner.</p>
                        </div>
                    </div>
                </div>

                <!-- Founders Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg4"></div>
                        <div>
                            <h6>Founders Rounds</h6>
                            <p>Founders Rounds are rounds where <span class="title small">voting is free</span>, but there will be no auctions and no drop to users. Instead, the winner goat goes to the <span class="title small">AlphaLabs wallet</span>. (Through this mechanism team transparently gets its allocation)</p>
                        </div>
                    </div>
                </div>

                <!-- Discounted Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg5"></div>
                        <div>
                            <h6>Discounted Rounds</h6>
                            <p>Discounted Rounds are rounds in which the <span class="title small">voting fee is discounted</span> by a specific percentage compared to a regular round in the same tier.</p>
                        </div>
                    </div>
                </div>

                <!-- Limited Participants Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg6"></div>
                        <div>
                            <h6>Limited Participants Rounds</h6>
                            <p>Limited Participants' Rounds accept only <span class="title small">a set number of first votes</span>, focusing competition on <span class="title small">early voting</span>. The rest follows the same structure as a regular round.</p>
                        </div>
                    </div>
                </div>

                <!-- Losers Rounds -->
                <div>
                    <div class="item card">
                        <div class="bg bg7"></div>
                        <div>
                            <h6>Losers Rounds</h6>
                            <p>Losers rounds are rounds where there’s <span class="title small">no voting fee or auction</span>, and the winner goat goes to a <span class="title small">lucky draw</span> winner amongst all the people who have lost a lucky draw from the previous losers’ round to this one.</p>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="!swiped">
                <figure>
                    <img class="center" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDIyLjVMMjAuOTgwOCAxNC45MzkyQzIxLjgyNjQgMTQuMTM4MSAyMy4xNTYxIDE0LjE1NjEgMjMuOTc5NyAxNC45Nzk3VjE0Ljk3OTdDMjQuODE5NCAxNS44MTk0IDI0LjgxOTQgMTcuMTgwNiAyMy45Nzk3IDE4LjAyMDNMMTkuNSAyMi41TDIyLjY3MTYgMjUuNjcxNkMyNC4yMzM3IDI3LjIzMzcgMjQuMjMzNyAyOS43NjYzIDIyLjY3MTYgMzEuMzI4NEwyMiAzMkMxOC42ODYzIDM1LjMxMzcgMTMuMzEzNyAzNS4zMTM3IDEwIDMyVjMyQzYuNjg2MjkgMjguNjg2MyA2LjY4NjI5IDIzLjMxMzcgMTAgMjBMMTIuMDkwNCAxNy45MDk2QzEyLjg2NzIgMTcuMTMyOCAxNC4xMjgyIDE3LjEzNzMgMTQuODk5NSAxNy45MTk3VjE3LjkxOTdDMTUuNjc3OCAxOC43MDkxIDE1LjY1NiAxOS45ODM4IDE0Ljg1MTMgMjAuNzQ2MkwxMy4yNzU2IDIyLjIzODkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTE0LjUgMTEuNzVMMTUuOTI2OCAxMC40OTExQzE5Ljk5NjUgNi45MDAxNCAyNi4wNzcxIDYuODE0MjcgMzAuMjQ2NiAxMC4yODg5TDMyIDExLjc1TTMyIDExLjc1VjguNU0zMiAxMS43NUgyOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K" alt="Swipe Icon">
                    <figcaption>Swipe to see more</figcaption>
                </figure>            
            </div>
        </template>
    </InfoLayout2>
</template>

<script>
export default {
    mounted() {
        const items = this.$refs.list.children;
        for(let i=0;i<items.length;i++) {
            const itemContainer = items[i];
            itemContainer.style.setProperty('--index', i);
            const item = itemContainer.children[0];

            if(i > this.currentIndex) {
                item.classList.add('under');
            }
        }
        

        const list = this.$refs.list;
        list.addEventListener('pointerdown', this.handleSlideStart);
        list.addEventListener('pointermove', this.handleSlide);
        list.addEventListener('pointerup', this.handleSlideEnd);
        list.addEventListener('touchstart', e => e.preventDefault());
        this._listWidth = list.clientWidth;
    },
    methods: {
        handleSlideStart(e) {
            e.preventDefault();

            this._touchStart = e.clientX;

            const items = this.$refs.list.querySelectorAll('.item');
            this._activeItem = items[this.currentIndex] || null;           
            this._prevItem = items[this.currentIndex - 1] || null; 
            this._nextItem = items[this.currentIndex + 1] || null;
        },
        handleSlide(e) {   
            if(this._touchStart === null || !this._activeItem) {
                return;
            }

            const dt = (e.clientX - this._touchStart) / this._listWidth;               
            
            if(dt == 0 || (dt < 0 && !this._prevItem) || (dt > 0 && !this._nextItem)) {
                return;
            }

            if(dt > 0){
                this._activeItem.setAttribute('data-drag', 'right');

                if(dt > 0.3) {
                    this.handleSlideEnd();
                    this.changeSelection(this.currentIndex + 1);
                }
            } else {
                this._activeItem.setAttribute('data-drag', 'left');
                
                if(dt < -0.3) {
                    this.handleSlideEnd();
                    this.changeSelection(this.currentIndex - 1);
                }
            }
        },
        handleSlideEnd() {
            this._activeItem?.removeAttribute('data-drag');
            
            this._activeItem = null;
            this._prevItem = null;
            this._nextItem = null;
            this._touchStart = null;
        },

        changeSelection(targetIndex) {
            this.currentIndex = targetIndex;        
            this.updateOrders();
            this.swiped = true;
        },
        updateOrders() {
            const items = this.$refs.list.children;
            for(let i=0;i<items.length;i++) {
                const itemContainer = items[i];
                itemContainer.style.setProperty('--index', i - this.currentIndex);
                const item = itemContainer.querySelector('.item');

                item.classList.remove('under', 'aside');
                if (i < this.currentIndex) {
                    item.classList.add('aside');
                }
                else if(i > this.currentIndex) {
                    item.classList.add('under');
                }
            }
        }
    },
    computed: {
        nextPage() {
            return this.swiped ? {buttonTitle: 'Next', id: 'GemMechanism16'} : null;
        },
    },
    data() {
        return {
            swiped: false,
            currentIndex: 0,
        };
    }
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.list {
    position: relative;
    isolation: isolate;
    height: 478px;

    --transition: .3s;

    & > div {
        &:has(.aside) {
            z-index: var(--index);
            animation: popTop var(--transition) linear;
        }

        position: absolute;
        z-index: calc(-1 * var(--index));    
    }    

    @keyframes popTop {
        0% {
            z-index: calc(100 - var(--index));
        }
        90% {
            z-index: calc(-1 * var(--index));
        }
    }
}

.item {
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    width: 326px;
    height: 400px; 
    text-align: left;
    box-shadow: 0px 4px 16px 0px #00000029;
    transition: var(--transition);
    translate: 0;

    .bg {
        height: 140px;    
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
}

.item.aside {
    transform: translateX(calc(100% + 25px)) translateY(15px) rotateZ(5deg);
    filter: brightness(20%) blur(2px);
}

.item.under {
    transform: scale(calc(1 - .05 * var(--index))) translateX(calc(-10px * var(--index))) translateY(calc(12px * var(--index))) rotateZ(calc(-5deg * var(--index)));
    filter: brightness(calc(100% - 20% * var(--index))) blur(calc(1px * var(--index)));
}

.item[data-drag="right"] {
    translate: 10px;
}

.item[data-drag="left"] {
    translate: -10px;
}

.bg1 { margin-top: -20px; }

.bg1 { @include responsive-bg-x4('@/assets/imgs/rounds/gifts.png'); }
.bg2 { @include responsive-bg-x4('@/assets/imgs/rounds/chest.png'); }
.bg3 { @include responsive-bg-x4('@/assets/imgs/rounds/double-reward.png'); }
.bg4 { @include responsive-bg-x4('@/assets/imgs/rounds/shares.png'); }
.bg5 { @include responsive-bg-x4('@/assets/imgs/rounds/goat.png'); }
.bg6 { @include responsive-bg-x4('@/assets/imgs/rounds/fence.png'); }
.bg7 { @include responsive-bg-x4('@/assets/imgs/rounds/target.png'); }

</style>