<template>
    <PageBaseLayout>
        <template #main>
            <h4>Selected: {{ selectedIndex }}</h4>

            <div class="list-container">
                <div class="list" :style="`--count: 5; --selected: ${selectedIndex};`">
                    <div class="dot"></div>
                    <div v-for="i in 5" :key="i" class="item" :style="`--index: ${i-1};`">
                        {{ i-1 }}
                    </div>
                </div>
            </div>


            <div>
                <button @click="()=>this.selectedIndex--">Prev</button>
                <button @click="()=>this.selectedIndex++">Next</button>
            </div>
        </template>

        <template #bottom>
            <ButtonLayout>
                <BaseButton pageId="HomePage">Go home!</BaseButton>
            </ButtonLayout>
        </template>
    </PageBaseLayout>

</template>

<script>
export default {
    data() {
        return {
            selectedIndex: 0,
        }
    }
}

</script>

<style scoped>

.dot {
    position: absolute;
    border-radius: 5px;
    width: 10px;
    height: 10px;
    background-color: red;
    left: 50%;
    top: 50%;
    transition: 0s;
    transform: translateX(-50%) translateY(-50%);
}

.list-container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    isolation: isolate;
}

.list {
    position: absolute;
    width: 0;
    height: 0;
    top: calc(50%);
    left: calc(50%);
    transition: .5;
    /* z-index: 100; */
    /* animation: autoRun 5s linear infinite; */
}
/* @keyframes autoRun {
    from { transform: var(--base-t) rotateY(0deg); }
    to { transform: var(--base-t) rotateY(-360deg); }
} */

.list .item {
    position: absolute;
    --w: 50px;
    --h: 50px;
    top: calc(50% - var(--h)*0.5 - 50px);
    left: calc(50% - var(--w)*0.5);
    width: var(--w);
    height: var(--h);
    
    transition: 0.2s;
    /* transform: rotateY(calc(var(--index) * 72deg)) translateZ(100px); */
    /* transform: rotateY(calc(360deg / var(--count) * var(--index))) translateX(-100px); */
    --diff: calc(var(--index) - var(--selected));
    --diff-abs: max(var(--diff),-1*var(--diff));
    /* transform: translateX(calc(10px * var(--diff))) rotateZ(calc(4deg * var(--diff))) translateY(calc(-5px * var(--diff))); */
    transform-origin: bottom right;
    transform: rotateZ(calc(-7deg * var(--diff))) scale(calc(1 - 0.05*var(--diff-abs)));
    z-index: calc(100 - var(--diff-abs));
    filter: blur(calc(1px * var(--diff-abs)));
    background-color: rgba(100, 20, 220, 1);
    border: solid 1px white;
}


</style>