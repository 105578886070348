<template>
    <nav>
        <button @click="goBack()" :class="{hidden: isAtHomePage}">
            <img src="@/assets/imgs/back.svg" alt="back">
        </button>

        <div class="title">{{ category }}</div>

        <button v-if="isAtHomePage" @click="closeApp()">
            <img src="@/assets/imgs/x.svg" alt="close">
        </button>
        <button v-else @click="goToHomePage()">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwTDcgMjBDNS4zNDMxNSAyMCA0IDE4LjY1NjkgNCAxN0w0IDEwLjg4MzRDNCA5Ljk3NTg5IDQuNDEwNzkgOS4xMTcxNyA1LjExNzMzIDguNTQ3NjdMMTAuMTE3MyA0LjUxNzQ5QzExLjIxNjIgMy42MzE3OCAxMi43ODM4IDMuNjMxNzggMTMuODgyNyA0LjUxNzQ5TDE4Ljg4MjcgOC41NDc2N0MxOS41ODkyIDkuMTE3MTcgMjAgOS45NzU4OSAyMCAxMC44ODM0TDIwIDE3QzIwIDE4LjY1NjkgMTguNjU2OSAyMCAxNyAyMFoiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTEwIDE3SDE0IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="home">
        </button>
    </nav>
    <component :is="pageComponent"/>
</template>

<script>
import pages from '@/pages.js';
import * as telegramManager from '@/telegramManager';

const components = {};
pages.registerComponents(components);

const homePage = pages.getEntryPageId();
const pageStack = [homePage];

// handle swipe-to-back events
let _instance = null;
let _touchStartX = null;
let _touchEndX = null;

function handleTouchStart(e) {
    _touchStartX = e.touches[0].pageX;
    _touchEndX = null;
}

function handleTouchMove(e) {
    _touchEndX = e.touches[0].pageX;
}

function handleTouchEnd() {
    if(!_touchEndX) {
        return;
    }

    const diff = _touchEndX - _touchStartX;
    const pageW = window.innerWidth;
    const thresholdRatio = 0.4;
    const startThresholdRatio = 0.1;

    if(diff > pageW * thresholdRatio && _touchStartX < pageW * startThresholdRatio) {
        _instance.goBack();
    }

    _touchStartX = null;
    _touchEndX = null;
}

function handleMount(instance) {
    _instance = instance;
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
}

function handleUnmount() {
    window.removeEventListener('touchstart', handleTouchStart);
    window.removeEventListener('touchmove', handleTouchMove);
    window.removeEventListener('touchend', handleTouchEnd);
    _instance = null;
}

export default {
    components,
    mounted() {
        handleMount(this);
    },
    unmounted() {
        handleUnmount();
    },
    provide(){
        // we need this reference since we're providing a function needing to access this instance
        const instance = this;
        return {
            goToPage(pageId) {
                if (pageId == instance.currentPage) {
                    console.warn('already at page:', pageId);
                    return;
                }
        
                instance.currentPage = pageId;
                pageStack.push(pageId);
            }
        };
    },
    methods: {   
        goToHomePage() {    
            pageStack.splice(1);
            this.currentPage = pageStack[0];
        },
        goBack() {
            if (pageStack.length > 1) {
                pageStack.pop();
                this.currentPage = pageStack[pageStack.length - 1];
            }
        },
        closeApp() {
            telegramManager.closeApp();
        },
    },
    computed: {
        pageComponent() {            
            return pages.getComponent(this.currentPage);
        },
        category() {
            return pages.getCategory(this.currentPage);
        },
        isAtHomePage() {
            return this.currentPage == homePage;
        }
    },
    data() {
        return {
            currentPage: homePage,
        };
    }
}
</script>

<style>
nav ul {
    position: relative;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

nav li {
    list-style: none;
    position: absolute;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
}

nav li:nth-child(1){
    left: 10px;
}

nav li:nth-child(2){
    right: 10px;
}

nav li:nth-child(3){
    right: 10px;
    left: 10px;
}
</style>