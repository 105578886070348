<template>
    <SurveyLayout class="override" :progressStep="6" :questionId="6" nextPageId="SurveyPage07" nextButtonTitle="Keep Going">
        <template #card-title>Number of Discounted Rounds</template>
        <template #question>How do you prefer discounted rounds to be?</template>
        
        <template #choice-a>Every 100 rounds <br><span class="small">with 40% discount</span></template>
        <template #choice-b>Every 150 rounds <br><span class="small">with 60% discount</span></template>
        <template #choice-c>Every 200 rounds <br><span class="small">with 80% discount</span></template>

        <template #tip-a>Vote for less discounts if you prefer <span class="prominent">more voting rounds</span></template>
        <template #tip-b>Vote for less rounds if you prefer <span class="prominent">higher discounts</span></template>

        <template #help-title>Discounted Rounds</template>
        <template #help-content>
            <p>Discounted Rounds are rounds in which the <span class="title small">voting fee is discounted</span> by a specific percentage compared to a regular round in the same tier.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/goat.png');
}

</style>