
export const ERROR_IDS = {
    INVALID_PLATFORM: 'invalid-platform',
    ALREADY_SUBMITTED: 'already-submitted',
    UNKNOWN_ERROR: 'unknown-error',
}

const ERROR_DETAILS_MAP = {
    [ERROR_IDS.INVALID_PLATFORM]: 'Invalid Platform',
    [ERROR_IDS.ALREADY_SUBMITTED]: 'Survey Already Submitted',
    [ERROR_IDS.UNKNOWN_ERROR]: 'Unknown Error'
};

function getErrorDetails(id) {
    return ERROR_DETAILS_MAP[id] ?? ERROR_DETAILS_MAP[ERROR_IDS.UNKNOWN_ERROR];
}

export function create(id) {
    return {error: id};
}

export function getDetails(error) {
    if(typeof error === 'string') {
        return getErrorDetails(error);
    } else {
        return getErrorDetails(error.error);
    }
}