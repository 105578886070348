<template>
    <SurveyLayout class="override" :progressStep="8" :questionId="8" nextPageId="SurveyPage09" nextButtonTitle="Only 3 steps to go">
        <template #card-title>Number of Double Reward Rounds</template>
        <template #question>How frequently do you want extra rewards?</template>
        
        <template #choice-a>Every 200 rounds <br><span class="small">More double reward rounds <br>Less playable rounds</span></template>
        <template #choice-b>Every 300 rounds <br><span class="small">Balanced double reward rounds <br>Balanced playable rounds</span></template>
        <template #choice-c>Every 400 rounds <br><span class="small">Less double reward rounds <br>More playable rounds</span></template>

        <template #tip-a>Vote for less values if you want to have <span class="prominent">more rounds to play</span></template>
        <template #tip-b>Vote for higher values if you want to have <span class="prominent">more chance to get more reward</span></template>

        <template #help-title>Double Reward Rounds</template>
        <template #help-content>
            <p>In Double Reward rounds, the winner claims both <span class="title small">the current and next round’s prizes</span>. The following round has free voting, no auctions, and the winning goat automatically transfers to the previous round's winner.</p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/rounds/double-reward.png');

    --img-override-origin: border-box;
}

</style>