<template>
    <InfoLayout2 class="alt-color" :nextPage="{buttonTitle: 'Place a Bid', id: 'GemMechanism12'}">
        <template #page-title>Regular Auction Round Rules</template>

        <template #header-title>&nbsp;</template>

        <template #main>
            <div class="list">
                <div class="item rounded">
                    <div>
                        <p>Each Auction Round runs in a</p>
                        <p class="title small">Limited Time Frame</p>
                    </div>
                    <div class="bg bg1"></div>
                </div>
                
                <div class="item rounded">
                    <div class="bg bg2"></div>
                    <div>
                        <p>A minimum bid price would be</p>
                        <div style="height: .5em;"></div>
                        <p class="title small">(X) * Price Pool</p>
                    </div>
                </div>

                <div class="item rounded">
                    <div>
                        <p>Each Auction Round runs in a</p>
                        <p class="title small">Limited Time Frame</p>
                    </div>
                    <div class="bg bg3"></div>
                </div>
                
                <div class="item rounded">
                    <div class="bg bg4"></div>
                    <div>
                        <p>A minimum bid price would be</p>
                        <div style="height: .5em;"></div>
                        <p class="title small">(X) * Price Pool</p>
                    </div>
                </div>

            </div>
        </template>
    </InfoLayout2>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    margin-top: -32px;

    .item {                
        display: flex;
        align-items: center;
        height: 180px;
        padding: 20px;        
        background-color: var(--color-secondary);
        border: solid 1px var(--color-secondary-light);

        & > div {
            width: 50%;
        }

        .bg {
            padding: 10px;            
            height: 100%;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}

.bg1 { @include responsive-bg-x4('@/assets/imgs/auction/auction-time.png'); }
.bg2 { @include responsive-bg-x4('@/assets/imgs/auction/bid-coin.png'); }
.bg3 { @include responsive-bg-x4('@/assets/imgs/auction/coins.png'); }
.bg4 { @include responsive-bg-x4('@/assets/imgs/auction/tiers.png'); }

</style>