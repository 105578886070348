<template>
    <div class="loading">
        <div class="lds-dual-ring"></div>
    </div>
</template>

<style scoped>
.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  padding: 0;
  margin: 0;
  
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 99 !important;
}

.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>