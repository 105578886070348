<template>
    <h3 class="light">{{timerText}}</h3>
</template>

<script>
export default {
    props: {
        targetDate: {
            type: Date, default: new Date()
        }
    },
    mounted() {
        this.updateTimer();
        this.interval = setInterval(this.updateTimer, 1000);
    },
    unmounted() {
        clearInterval(this.interval);
    },
    methods: {
        updateTimer() {
            let remaining = Math.floor((this.targetDate - new Date()) / 1000);
            if (remaining < 0) {
                remaining = 0;
            }

            let seconds = Math.floor(remaining % 60);
            remaining -= seconds;
            let minutes = Math.floor((remaining / 60) % 60);
            remaining -= minutes * 60;
            let hours = Math.floor((remaining / 3600) % 24);
            remaining -= hours * 3600;
            let days = Math.floor(remaining / (3600 * 24));
            
            let result = [];
            if(days > 0) {
                result.push(days+'d');
            }
            if(hours>0) {
                result.push(hours+'h');
            }
            if(minutes>0) {
                result.push(minutes+'m');
            }
                
            result.push(seconds+'s');

            this.timerText = result.join(' ');
        }
    },
    data() {
        return {
            timerText: ''
        };
    }
}
</script>