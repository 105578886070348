<template>
    <PageBaseLayout>
        <template #main>
            <div class="container">
                <div class="header bar">
                    <div class="help">
                        <button @click="showHelp"><img src="@/assets/imgs/survey/help.svg"></button>
                    </div>
                    <div class="progress">
                        <img class="clamp-width" :data-step="progressStep" src="" alt="Progress indicator">
                        <p>{{ progressStep }}</p>
                    </div>
                </div>

                <div class="header card">
                    <h6><slot name="card-title">[Card Title]</slot></h6>
                    <div class="card-image"></div>
                </div>

                <div class="space-line"></div>
                <p class="title large">
                    <slot name="question">
                        [Question]
                    </slot>
                </p>
                <div class="space-line"></div>

                <div v-if="isWalletInput" class="inputs">
                    <input ref="wallet" @keyup.prevent="handleKeyUp" @change.prevent="validateInput" type="text" name="wallet" placeholder="Enter your EVM wallet address">
                    <input ref="email" @keyup.prevent="handleKeyUp" @change.prevent="validateInput" type="email" name="email" placeholder="Enter you e-mail (optional)">
                </div>
                <div v-else class="list" ref="choices">
                    <ButtonLayout>
                        <button @click="choose" data-choice-id="1"><slot name="choice-a">Choice A</slot></button>
                        <button @click="choose" data-choice-id="2"><slot name="choice-b">Choice B</slot></button>
                        <button @click="choose" data-choice-id="3"><slot name="choice-c">Choice C</slot></button>
                    </ButtonLayout>

                    <InfoBox class="tips">
                        <ul>
                            <li><p class="label large"><slot name="tip-a">Tip A</slot></p></li>
                            <li><p class="label large"><slot name="tip-b">Tip B</slot></p></li>
                        </ul>
                    </InfoBox>
                </div>

            </div>

            <ModalPopUp ref="modal">
                <p class="small"><slot name="help-title">Giveaway Rounds</slot></p>
                <div class="space-line"></div>

                <div class="card-image help-image"></div>
                <div class="help-content">
                    <div>
                        <slot name="help-content">[Help content]</slot>
                    </div>
                </div>
                <div class="space-line"></div>
                <button @click="hideHelp" style="width: 100%;">Got it</button>
            </ModalPopUp>

            <LoadingOverlay v-if="isLoading"></LoadingOverlay>
        </template>

        <template #bottom>
            <div class="bottom">
                <BaseButton @button-clicked="answerQuestion" :active="(isWalletInput && readyToSubmit) || answerId > 0" :pageId="isWalletInput ? null : nextPageId">{{ nextButtonTitle }} &nbsp;<i class="icon middle next"></i></BaseButton>
            </div>

        </template>
    </PageBaseLayout>
</template>

<script>
import * as survey from '@/survey';
import * as backend from '@/backend';
import * as telegram from '@/telegramManager';
import * as errors from '@/errors';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default {
    inject: ['goToPage'],
    mounted() {
        if (this.isWalletInput) {
            const wallet = survey.getWallet();
            if (wallet) {
                this.$refs.wallet.value = wallet;
            }
    
            const email = survey.getEmail();
            if (email) {
                this.$refs.email.value = email;
            }
    
            this.validateInput();
        } else {
            if (survey.isAnswered(this.questionId)) {
                this.answerId = survey.getAnswer(this.questionId) ?? 0;
                const target = this.$refs.choices.querySelector(`button[data-choice-id="${this.answerId}"]`);
                target.setAttribute('selected', true);
            }
        }

    },
    props: {
        progressStep: {type: Number, default: 1},
        questionId: {type: Number, default: 1},
        nextPageId: {type: String, default: ''},
        nextButtonTitle: {type: String, default: 'Next'},
        isWalletInput: {type: Boolean, default: false}
    },
    methods: {
        handleKeyUp(e) {
            if(e.key == 'Enter') {
                e.target.blur();
            }
            this.validateInput();
        },
        validateInput() {
            this.readyToSubmit = false;

            const wallet = this.$refs.wallet.value;
            if (!wallet || wallet.length < 1) {
                return;
            }

            const email = this.$refs.email.value;
            if (email && !EMAIL_REGEX.test(email)) {
                return;     
            }

            this.readyToSubmit = true;
        },
        async answerQuestion() {
            if (this.isWalletInput) {

                this.validateInput();
                if (!this.readyToSubmit) {
                    console.error('unexpected state: page is not ready to be submitted!');
                    return;
                }

                const wallet = this.$refs.wallet.value;
                const email = this.$refs.email.value;

                this.isLoading = true;
                survey.setWallet(wallet);
                if (email) {
                    survey.setEmail(email);
                }

                this.isLoading = true;
                try {
                    const response = await backend.submitSurvey();
                    if(response.error) {
                        telegram.showPopupSimple('Error', errors.getDetails(response.error));
                        console.warn('response:', response);
                    } else {
                        this.goToPage(this.nextPageId);
                    }
                } catch (err) {
                    console.error(err);
                    telegram.showPopupSimple('Error', 'Something went wrong, try again!');
                } finally {
                    this.isLoading = false;
                }

            } else {
                const answerId = this.answerId;
                if(answerId <= 0) {
                    console.error('invalid answer:', answerId);
                    return;
                }
    
                survey.answer(this.questionId, answerId);
            }
        },
        choose(e) {
            const choices = this.$refs.choices.querySelectorAll('button');
            const target = e.target;

            for (const choice of choices) {
                choice.removeAttribute('selected');
            }
            target.setAttribute('selected', true);

            this.answerId = parseInt(target.getAttribute('data-choice-id'));
        },
        showHelp() {
            this.$refs.modal.showModal();
        },
        hideHelp() {
            this.$refs.modal.hideModal();
        }
    },
    data() {
        return {
            answerId: 0,
            readyToSubmit: false,
            isLoading: false,
        };
    }
}
</script>

<style lang="scss" scoped>

@use '@/assets/css/_tools' as *;

.header.bar {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: -20px;

    .help button {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .progress {
        position: relative;
        display: flex;
        justify-content: center;

        p {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            user-select: none;
        }
    }
}

.header.card {
    display: flex;
    align-items: center;
    height: 164px;
    margin-top: 10px;
    margin-bottom: 10px;

    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzU4IiBoZWlnaHQ9IjE2NCIgdmlld0JveD0iMCAwIDM1OCAxNjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2JfOTI3XzQ1KSI+CjxyZWN0IHdpZHRoPSIzNTgiIGhlaWdodD0iMTY0IiBmaWxsPSIjNDY2NzhDIi8+CjxyZWN0IHdpZHRoPSIzNTgiIGhlaWdodD0iMTY0IiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWxfOTI3XzQ1KSIgZmlsbC1vcGFjaXR5PSIwLjciLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzkyN180NSIgeD0iLTQiIHk9Ii00IiB3aWR0aD0iMzY2IiBoZWlnaHQ9IjE3MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIyIi8+CjxmZUNvbXBvc2l0ZSBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0iaW4iIHJlc3VsdD0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl85MjdfNDUiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2JhY2tncm91bmRCbHVyXzkyN180NSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDBfcmFkaWFsXzkyN180NSIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgzNTggMTY0KSByb3RhdGUoLTE0OS4xNzIpIHNjYWxlKDI1Mi43MDQgMTcyLjk0NSkiPgo8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IndoaXRlIiBzdG9wLW9wYWNpdHk9IjAiLz4KPC9yYWRpYWxHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
    background-position: right;
    background-repeat: no-repeat;
    background-color: #46678c;
    overflow: hidden;

    h6, .card-image {
        width: 50%;
        height: 100%;
    }

    h6 {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 32px;
        padding-right: 16px;
    }
}

.progress {
    img[data-step="1"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNSIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMTEuNDI4NSAyLjI4NTE2KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI1LjYwMDEiIHk9IjcuNzcxNDgiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDYuMzk5OSA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDQiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUuNDg1NiAxNS4wODU5KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjIzLjMxNDIiIHk9IjIyLjQwMDQiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDguNjg1NzkgMjIuNDAwNCkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjkuNjAwMSIgeT0iMjYuMDU2NiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8L3N2Zz4K"); }
    img[data-step="2"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNSIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODUgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42MDAxIiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA2LjM5OTkgNy43NzE0OCkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNi41MTQ0IiB5PSIxNS4wODU5IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTYgMTUuMDg1OSkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyMy4zMTQyIiB5PSIyMi40MDA0IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA4LjY4NTc5IDIyLjQwMDQpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMTYuOTE0MyIgeT0iMjYuMDU2NiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSI5LjYwMDEiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPC9zdmc+Cg=="); }
    img[data-step="3"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNSIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODUgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42MDAxIiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi4zOTk5IDcuNzcxNDgpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjYuNTE0NCIgeT0iMTUuMDg1OSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNS40ODU2IDE1LjA4NTkpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjMuMzE0MiIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgOC42ODU3OSAyMi40MDA0KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjE2LjkxNDMiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iOS42MDAxIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+Cjwvc3ZnPgo="); }
    img[data-step="4"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi40MDAwMiA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDMiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTcyIDE1LjA4NTkpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjMuMzE0MyIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgOC42ODU2NyAyMi40MDA0KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjE2LjkxNDMiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iOS41OTk5OCIgeT0iMjYuMDU2NiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8L3N2Zz4K"); }
    img[data-step="5"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi40MDAwMiA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDMiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTcyIDE1LjA4NTkpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjMuMzE0MyIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDguNjg1NjcgMjIuNDAwNCkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjkuNTk5OTgiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPC9zdmc+Cg=="); }
    img[data-step="6"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi40MDAwMiA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDMiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTcyIDE1LjA4NTkpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjMuMzE0MyIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDguNjg1NzMgMjIuNDAwNCkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSI5LjU5OTk4IiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+Cjwvc3ZnPgo="); }
    img[data-step="7"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi4zOTk5OSA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDMiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTcyIDE1LjA4NTkpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjMuMzE0MyIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDguNjg1NzMgMjIuNDAwNCkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSI5LjYwMDAxIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8L3N2Zz4K"); }
    img[data-step="8"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi40IDcuNzcxNDgpIiBmaWxsPSIjM0Y0OTdBIiBmaWxsLW9wYWNpdHk9IjAuNjQiLz4KPHJlY3QgeD0iMjYuNTE0MyIgeT0iMTUuMDg1OSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUuNDg1NzIgMTUuMDg1OSkiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyMy4zMTQzIiB5PSIyMi40MDA0IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgOC42ODU3MSAyMi40MDA0KSIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSI5LjYiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+Cjwvc3ZnPgo="); }
    img[data-step="9"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi4zOTk5OSA3Ljc3MTQ4KSIgZmlsbD0iIzNGNDk3QSIgZmlsbC1vcGFjaXR5PSIwLjY0Ii8+CjxyZWN0IHg9IjI2LjUxNDMiIHk9IjE1LjA4NTkiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1LjQ4NTcyIDE1LjA4NTkpIiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHg9IjIzLjMxNDMiIHk9IjIyLjQwMDQiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA4LjY4NTczIDIyLjQwMDQpIiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHg9IjE2LjkxNDMiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+CjxyZWN0IHg9IjkuNjAwMDEiIHk9IjI2LjA1NjYiIHdpZHRoPSI1LjQ4NTcxIiBoZWlnaHQ9IjUuNDg1NzEiIHJ4PSIyLjc0Mjg2IiBmaWxsPSIjMkU0QkUwIi8+Cjwvc3ZnPgo="); }
    img[data-step="10"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMzRjQ5N0EiIGZpbGwtb3BhY2l0eT0iMC42NCIvPgo8cmVjdCB4PSIyNS42IiB5PSI3Ljc3MTQ4IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNi40MDAwMiA3Ljc3MTQ4KSIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSIyNi41MTQzIiB5PSIxNS4wODU5IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgNS40ODU3MiAxNS4wODU5KSIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSIyMy4zMTQzIiB5PSIyMi40MDA0IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgOC42ODU3MyAyMi40MDA0KSIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSIxNi45MTQzIiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8cmVjdCB4PSI5LjU5OTk4IiB5PSIyNi4wNTY2IiB3aWR0aD0iNS40ODU3MSIgaGVpZ2h0PSI1LjQ4NTcxIiByeD0iMi43NDI4NiIgZmlsbD0iIzJFNEJFMCIvPgo8L3N2Zz4K"); }
    img[data-step="11"] { content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTMuMjU3MiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjAuNTcxNCIgeT0iMi4yODUxNiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDExLjQyODYgMi4yODUxNikiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjUuNiIgeT0iNy43NzE0OCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDYuNDAwMDIgNy43NzE0OCkiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjYuNTE0MyIgeT0iMTUuMDg1OSIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDUuNDg1NzIgMTUuMDg1OSkiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMjMuMzE0MyIgeT0iMjIuNDAwNCIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3Qgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDguNjg1NjcgMjIuNDAwNCkiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iMTYuOTE0MyIgeT0iMjYuMDU2NiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPHJlY3QgeD0iOS41OTk5OCIgeT0iMjYuMDU2NiIgd2lkdGg9IjUuNDg1NzEiIGhlaWdodD0iNS40ODU3MSIgcng9IjIuNzQyODYiIGZpbGw9IiMyRTRCRTAiLz4KPC9zdmc+Cg=="); }
}

.card-image {
    padding: 16px 16px 16px 0;
    background-repeat: no-repeat;
    background-size: cover;

    background-image: var(--img-override);
    background-origin: var(--img-override-origin, content-box);
    background-position: var(--img-override-position, center);
}

.list button:not([selected]) {
    background-color: var(--color-secondary-dark);
    border: solid 1px;
    border-color: var(--color-secondary-light);
}

::v-deep(.list .small) {
    color: var(--color-surface-dark);
}

.tips ul {
    margin: 0;
    padding: 0 25px 0 25px;
    text-align: left;
    list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTFMMTEuMjkyOSAxNC4yOTI5QzExLjY4MzQgMTQuNjgzNCAxMi4zMTY2IDE0LjY4MzQgMTIuNzA3MSAxNC4yOTI5TDE2IDExIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    
    li:last-child {
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDEzTDEyLjcwNzEgOS43MDcxMUMxMi4zMTY2IDkuMzE2NTggMTEuNjgzNCA5LjMxNjU4IDExLjI5MjkgOS43MDcxMUw4IDEzIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    }
}

.inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.help-image {
    height: 124px;
    background-size: contain;
    background-origin: border-box;
}

.help-content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: left;
    margin-top: 16px;
}

</style>