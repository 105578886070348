<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header>Perks</template>
        
        <template #bottom>
            <div class="bottom">
                <BaseButton pageId="AlphaGoat04">Is that all you got? <i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout1>
</template>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/logo.svg');
}
</style>