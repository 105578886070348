<template>
    <SlideIntroLayout class="override" :slideIndex="0" :durationMs="5000" nextPageId="AlphaGoat01S2">
        <template #description>
            Alpha G.O.A.T; a collection of 10,000 pieces of the Greatest of all Time NFTs
        </template>
    </SlideIntroLayout>
</template>

<script>
export default {
    
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/intro/intro-1.png');
}

</style>