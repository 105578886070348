import storage from '@/storage.js';

const MAX_QUESTIONS = 64;

var currentData = {
    questions: {},
    wallet: null,
    email: null
};

/**
 * @param {number} questionId 
 * @param {number} answerId 
 */
export function answer(questionId, answerId) {
    if (typeof(questionId) != 'number' || typeof(answerId) != 'number') {
        console.error('invalid question/answer id type. given types:', typeof(questionId), typeof(answerId));
        return;
    }

    if (questionId < 0 || questionId > 255) {
        console.error('question id out of range:', questionId);
        return;
    }

    if (answerId < 0 || answerId > 15) {
        console.error('answer id out of range:', answerId);
        return;
    }

    let id = questionId.toString(16).padStart(2, '0');
    currentData.questions[id] = answerId.toString(16);
    saveLocal();
}

/**
 * @param {string} wallet 
 */
export function setWallet(wallet) {
    currentData.wallet = wallet;
    saveLocal();
}

export function getWallet() {
    return currentData.wallet;
}

/**
 * @param {string} email
 */
export function setEmail(email) {
    currentData.email = email;
    saveLocal();
}

export function getEmail() {
    return currentData.email;
}

/**
 * @param {number} questionId 
 */
export function isAnswered(questionId) {
    let id = questionId.toString(16).padStart(2, '0');
    return id in currentData.questions;
}

/**
 * @param {number} questionId 
 */
export function getAnswer(questionId) {
    let id = questionId.toString(16).padStart(2, '0');
    if (id in currentData.questions) {
        return parseInt(currentData.questions[id], 16);
    }

    return null;
}

export function getAnsweredQuestionIds() {
    let result = [];
    for(let id in currentData.questions) {
        result.push(parseInt(id, 16));
    }
    return result;
}

export function serialize(stringify = true) {
    return serializeInternal(currentData, stringify);
}

function saveLocal() {
    storage.setItem('survey', serializeInternal(currentData));
}

function loadLocal() {
    let dataStr = storage.getItem('survey');
    if(dataStr) {
        let data = deserialize(dataStr);
        if(data) {
            currentData = data;
        }
    }
}

function serializeInternal(data, stringify = true) {
    let questionData = '';
    let count = 0;
    for(let key in data.questions) {
        if(++count > MAX_QUESTIONS) {
            console.error('too many questions to serialize! ignoring the rest.');
            break;
        }
        questionData += `${key}${data.questions[key]}`;
    }

    let result = {
        w: data.wallet,
        e: data.email,
        qd: questionData,
        h: hashCode(data.wallet+questionData)
    };

    if (stringify) {
        return JSON.stringify(result);
    } else {
        return result;
    }
}

function deserialize(dataStr) {
    let obj = JSON.parse(dataStr);

    if (obj.h != hashCode(obj.w + obj.qd)) {
        console.error('invalid serialized data');
        return null;
    }

    let data = {
        wallet: obj.w,
        email: obj.e,
        questions: {}
    };

    // data structure: [question ID: XX][answer ID: X] (example: 0A2)
    for(let i=0;i<obj.qd.length;i+=3) {
        let questionId = obj.qd.substring(i, i+2);
        let answerId = obj.qd[i+2];
        data.questions[questionId] = answerId;
    }

    return data;
}

function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // convert to 32bit integer
    }
    return hash;
}

loadLocal();
