<template>
    <InfoLayout2 :nextPage="{buttonTitle: 'I’ll pay the fee to vote', id: 'GemMechanism09Lose'}" bottomInfo="It’s a simulation and no real funds are involved">
        <template #header-title>&nbsp;</template>

        <template #main>
            <div class="container">
                <p class="small">If you want to vote you have to pay</p>
                <p class="title">Voting Fee</p>
                <br><br>
                <div class="box rounded">
                    <div>
                        <p>Round Tier:</p>
                        <p class="title large">Common</p>
                    </div>
                    <div class="rounded">
                        <p class="large" style="color: var(--color-secondary-variant);">Voting fee</p>
                        <h6>0.0001 ETH</h6>
                    </div>
                </div>
            </div>
            

        </template>
    </InfoLayout2>
</template>


<style lang="scss" scoped>
div.box {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    background-color: var(--color-secondary);
    
    div.rounded {
        padding: inherit;
        background-color: var(--color-secondary-dark);
    }
}
</style>