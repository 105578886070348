<template>
    <InfoLayout1>
        <template #header-img><div class="header-img"></div></template>
        <template #header>Manifesto</template>
        <template #title>Manifesto title</template>
        <template #subtitle>Manifesto subtitle</template>
        <template #content>This is the content in the Manifesto page.</template>
    </InfoLayout1>
</template>

<style scoped>
div.header-img {
    background-image: url('@/assets/imgs/logo.svg');
}
</style>