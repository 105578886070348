<template>
    <div @click="hideModal" class="modal-overlay" :data-visible="false">
        <div @click="(e)=>e.stopPropagation()" class="card modal-content">
            <div class="close">
                <button @click="hideModal">
                    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDZMNiAxOCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNNiA2TDE4IDE4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=" alt="Close">
                </button>
            </div>
            <div style="margin-top: -48px;">
            </div>
            <slot>
                [MODAL CONTENT]
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        hideModal() {
            this.$el.setAttribute('data-visible', false);
        },
        showModal() {
            this.$el.setAttribute('data-visible', true);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: var(--vh, 100vh);
    z-index: 10;
    
    transition: visibility .5s, background-color .5s;
    
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.8);

    .modal-content {
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 20px;
        padding-top: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        transition: transform 0.5s;
        transform: translateY(0%);
    }

    &[data-visible="false"] {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0);

        .modal-content {
            transform: translateY(100%);
        }
    }
}

.close {
    text-align: right;

    button {
        background: none;
        height: 24px;
        border: none;
        padding: 0;
        margin-top: -5px;
    }
}

</style>