<template>
    <InfoLayout2 :nextPage="{buttonTitle: 'Continue', id: 'GemMechanism05'}">
        <template #header-title>
            <p class="small">Every drop begins with a</p>
            <p class="title">Voting Round</p>
        </template>

        <template #main>
            <VoteBox :choices="[
                {version:'A', image:require('@/assets/imgs/goat-nft-1.jpg')}, 
                {version:'B', image:require('@/assets/imgs/goat-nft-2.jpg')}
                ]">
            </VoteBox>

            <br>
            <p class="large">You get to choose</p>
            <p class="large"><span class="title">which one</span> makes its way</p>
            <p class="large">to the <span class="title">final collection</span></p>

        </template>
    </InfoLayout2>
</template>