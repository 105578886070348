<template>
    <SurveyLayout class="override" :progressStep="3" :questionId="3" nextPageId="SurveyPage04">
        <template #card-title>Minimum Bid Price</template>
        <template #question>What should be the multiplier for minimum bids?</template>
        
        <template #choice-a>Price Pool balance x 1.5</template>
        <template #choice-b>Price Pool balance x 1.8</template>
        <template #choice-c>Price Pool balance x 2</template>

        <template #tip-a>Vote for lower multipliers if you prefer <span class="prominent">Easier Participation</span></template>
        <template #tip-b>Vote for higher multipliers if you prefer <span class="prominent">Less Competition</span></template>

        <template #help-title>Minimum Bid Price</template>
        <template #help-content>
            <p>A minimum bid price would be: <br><span class="title small">(X) * Price Pool</span></p>
        </template>
    </SurveyLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/auction/bid-coin.png');
}

</style>