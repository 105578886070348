<template>
    <PageBaseLayout>
        <template #main>
            <h1>[PENDING]</h1>

            <div class="list-container">
                <div class="list" style="--count: 10">
                    <div class="dot"></div>
                    <div v-for="i in 10" :key="i" class="item" :style="`--index: ${i};`">
                        {{ i }}
                    </div>
                    <div v-for="i in 10" :key="i" class="item wall-left" :style="`--index: ${i};`">
                    </div>
                    <div v-for="i in 10" :key="i" class="item wall-right" :style="`--index: ${i};`">
                    </div>
                </div>
            </div>

        </template>

        <template #bottom>
            <ButtonLayout>
                <BaseButton pageId="HomePage">Go home!</BaseButton>
            </ButtonLayout>
        </template>
    </PageBaseLayout>

</template>

<script>
export default {
    data() {
        return {
            tierNames: ['Common', 'Uncommon', 'Special', 'Rare', 'Exceptional', 'Masterpiece', 'Legendary'],
            tierTotals: [3500, 2500, 2000, 1500, 400, 90, 10]
        }
    }
}

</script>

<style scoped>

.list-container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
}

.list {
    /* position: relative;
    top: 50%;
    left: 50%;
    width: 320px;
    height: 320px; */
    /* transform-style: preserve-3d;
    transform: perspective(1000px);
    transform: translateX(-50%) translateY(-50%); */
    
    /* background-color: red; */
    
    position: absolute;
    width: 200px;
    height: 250px;
    top: calc(50% - 250px/2);
    left: calc(50% - 200px/2);
    transform-style: preserve-3d;
    transition: 4s;
    --base-t: perspective(1000px) rotateX(-35deg) rotateY(0deg);
    transform: var(--base-t);
    z-index: 2;
    /* animation: autoRun 5s linear infinite; */
}
@keyframes autoRun {
    from { transform: var(--base-t) rotateY(0deg); }
    to { transform: var(--base-t) rotateY(-360deg); }
}

.list .dot {
    position: absolute;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    background-color: red;
    left: 50%;
    top: 50%;
    transition: 0s;
    transform: translateX(-50%) translateY(-50%);
}

.list .item {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    /* transform: rotateY(calc(var(--index) * 72deg)) translateZ(100px); */
    transform: rotateY(calc(360deg / var(--count) * var(--index))) translateX(-100px);
    background-color: rgba(100, 20, 220, 0.5);
}

.list .wall-left {
    position: absolute;
    background-color: blue;
    left: calc(50% - 5px);
    width: 10px;
    transform: rotateY(calc(15deg + 360deg / var(--count) * var(--index))) translateZ(-125px) translateX(-1px);
}

.list .wall-right {
    position: absolute;
    background-color: yellow;
    left: calc(50% - 5px);
    width: 10px;
    transform: rotateY(calc(15deg + 360deg / var(--count) * var(--index))) translateZ(-75px);
}

</style>