import { createApp } from 'vue'
import App from '@/App.vue'

import BaseButton from './components/BaseButton.vue';
import ButtonLayout from './components/ButtonLayout.vue';
import ScrollableContainer from './components/ScrollableContainer.vue';
import PageBaseLayout from './components/PageBaseLayout.vue';
import LoadingOverlay from './components/LoadingOverlay.vue';
import ModalPopUp from './components/ModalPopUp.vue';
import CountdownTimer from './components/CountdownTimer.vue';
import SurveyLayout from './components/SurveyLayout.vue';
import InfoLayout1 from './components/InfoLayout1.vue';
import InfoLayout2 from './components/InfoLayout2.vue';
import VoteCard from './components/VoteCard.vue';
import VoteBox from './components/VoteBox.vue';
import InfoBox from './components/InfoBox.vue';
import SlideIntroLayout from './components/SlideIntroLayout.vue';

import '@/assets/css/style.scss';

import * as telegramManager from "@/telegramManager"

telegramManager.initialize();

// set --vh updater for iOS
window.addEventListener('resize', onResize);
function onResize() {
    document.querySelector(':root').style.setProperty('--vh', `${window.innerHeight}px`);
}
onResize();

const app = createApp(App);

const components = {
    BaseButton, 
    ButtonLayout, 
    ScrollableContainer, 
    PageBaseLayout, 
    LoadingOverlay, 
    ModalPopUp, 
    CountdownTimer, 
    SurveyLayout, 
    InfoLayout1,
    InfoLayout2,
    VoteCard,
    VoteBox,
    InfoBox,
    SlideIntroLayout,
};

for(let key in components) {
    app.component(key, components[key]);
}

app.mount('#app')
