<template>
    <InfoLayout2 class="alt-color">
        <template #header-title>          
            <div style="height: 85px;">
                <div class="clock">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.9999" cy="12.8203" r="8.25" stroke="white" stroke-width="1.5"/>
                    <path d="M10.5 1.5H12.0003M13.5 1.5H12.0003M12.0003 1.5V4" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M18.7677 4L19.8286 5.06088M20.889 6.12132L19.8286 5.06088M19.8286 5.06088L18.0608 6.82865" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M12 8.99805V12.9107L15 14.998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <p class="small">Voting ends in</p>
                <h6>{{timer}}</h6>
            </div>  
        </template>

        <template #main>
            <div class="inner">
                <VoteCard captionTitle="Winner G.O.A.T" captionContent="Number 1">
                </VoteCard>
    
                <div class="prices rounded">
                    <div class="choice">
                        <p class="small">Price Pool</p>
                        <p class="title">$142.50</p>
                    </div>
                    <div class="choice" :class="{error: !validInput}">
                        <p class="small">Minimum Bid</p>
                        <p class="title">${{(minBid * 0.01).toFixed(2)}}</p>
                    </div>
                    <div class="choice">
                        <p class="small">Current Bid</p>
                        <p class="title">$3.00</p>
                    </div>
                </div>
            </div>

        </template>

        <template #bottom>
            <div class="bottom">
                <div class="number-box">
                    <button @click="addBid(-50)"><img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTJIMTgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==" alt="Minus"></button>
                    <div class="field" :class="{error: !validInput}">
                        <p class="title small">${{(bid * 0.01).toFixed(2)}}</p>
                    </div>
                    <button @click="addBid(50)"><img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDZWMThNNiAxMkgxOCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K" alt="Plus"></button>
                </div>
                <BaseButton pageId="GemMechanism13" :disabled="!validInput">Submit &nbsp;<i class="icon middle next"></i></BaseButton>
            </div>
        </template>
    </InfoLayout2>
</template>

<script>
export default {
    methods: {
        addBid(amount) {
            this.bid = Math.max(Math.min(this.maxBid, this.bid + amount), 0);
        }
    },
    computed: {
        validInput() {
            return this.bid >= this.minBid && this.bid <= this.maxBid;
        }
    },
    data() {
        return {
            minBid: 200,
            maxBid: 9950,
            timer: '00:10',
            bid: 350,
        };
    }
};
</script>

<style lang="scss" scoped>

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.prices {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 337px;
    height: 88px;

    border: solid 1px var(--color-secondary-light);
    background-color: var(--color-secondary-dark);

    .choice {
        width: 115px;
        border-right: solid 1px var(--color-secondary);
        
        &:last-child {
            border: none;
        }

        &.error {
            color: var(--color-accent);
        }

        p.small {
            color: var(--color-surface-dark);
        }
    }
}

.bottom {
    border: 1px solid var(--color-secondary-light);
    border-bottom: none;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    background-color: var(--color-secondary-dark);

    .number-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-block: 20px;

        button {
            width: 64px;
            height: 64px;
            border-radius: 50%;
        }

        .field {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 214px;
            height: 84px;
            background-color: var(--color-secondary-dark);
            border: solid 1px var(--color-secondary-light);
            border-radius: 64px;

            &.error {
                border-color: var(--color-accent);
            }
        }
    }
}

.clock {
    width: 24px;    
    height: 24px; 
    margin-top: -12px;
    margin-inline: auto;
    
    svg path, svg circle {
        stroke: var(--tint, var(--color-surface));
    }
}

.accent {
    --tint: var(--color-accent);
    color: var(--color-accent);
}

</style>