<template>
    <SlideIntroLayout class="override" :slideIndex="3" :durationMs="5000" :autoNext="false" nextPageId="AlphaGoat01Tiers" nextButtonTitle="Explore tiers">
        <template #description>
            Distributed in 6 tiers
        </template>
    </SlideIntroLayout>
</template>

<script>
export default {
    
};
</script>

<style lang="scss" scoped>
@use '@/assets/css/_tools' as *;

.override {
    @include responsive-field-x4(--img-override, '@/assets/imgs/intro/intro-4.png');
}

</style>